import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import { Container } from './Container';
import FacebookIcon from '/public/static/Facebook.svg';
import TwitterIcon from '/public/static/Twitter.svg';
import InstagramIcon from '/public/static/Instagram.svg';
import LinkedinIcon from '/public/static/Linkedin.svg';
import DHIcon from '/public/static/DoclerHoldingLogo.svg';
import { SocialLink } from './SocialLink';
import { ListItem } from './ListItem';
import { Route } from './Header';
import { getStaticUrl } from '../helpers';
import { useRouter } from 'next/router';
import {
  ApiCompanyCompany,
  ApiLegalDocumentLegalDocument,
} from '../types/strapi/contentTypes';

export interface IFooterDetails {
  company_details: [];
  company_entities: { data: ApiCompanyCompany[] };
  facebook_url: string;
  twitter_url: string;
  instagram_url: string;
  linkedin_url: string;
  map_image: { attributes: { url: string } };
  company_name: string;
  companies_title: string;
  legal_documents: ApiLegalDocumentLegalDocument[];
  legal_documents_title: string;
}

interface IFooterProps {
  routes: Route[];
  footer: IFooterDetails;
}

interface ICompanyPhoneMail {
  company: string;
  phone: string;
  mail: string;
  className: string;
}

interface ISiteLinks {
  title: string;
  routes: Route[];
}

interface ICompaniesLink {
  data: ApiCompanyCompany[];
}

interface ICompaniesLinks {
  title: string;
  routes: ICompaniesLink;
}

const MenuItemsBlock: FC<ISiteLinks> = ({ title, routes }) => {
  const { asPath } = useRouter();
  return (
    <div className="uppercase whitespace-nowrap lg:pr-[237px]">
      <div className="font-bold lg-:pb-3">{title}</div>
      <div className="flex flex-col lg-:gap-3">
        {routes
          .filter(({ attributes: { value } }) => value !== '/')
          .map(({ attributes: { label, value } }) => (
            <ListItem
              key={label}
              label={label}
              value={value}
              className={`after:bottom-0 ${
                asPath === value
                  ? 'after:w-full'
                  : 'after:w-0 hover:after:w-[25px]'
              }`}
            />
          ))}
      </div>
    </div>
  );
};

const CompaniesBlock: FC<ICompaniesLinks> = ({ title, routes }) => {
  return (
    <div className="w-full uppercase lg-:hidden">
      <div className="uppercase font-bold">{title}</div>
      <div className="flex">
        <div className="flex flex-col h-fit flex-1">
          {routes.data.slice(0, 5).map(({ attributes: { name, url } }) => (
            <ListItem
              key={name}
              label={name}
              value={url}
              className="after:w-0 after:bottom-0 hover:after:w-[25px]"
            />
          ))}
        </div>
        <div className=" flex flex-col h-fit flex-1">
          {routes.data.slice(5, 10).map(({ attributes: { name, url } }) => (
            <ListItem
              key={name}
              label={name}
              value={url}
              className="after:w-0 after:bottom-0 hover:after:w-[25px]"
            />
          ))}
        </div>
        <div className=" flex flex-col h-fit ml-auto mr-0">
          {routes.data.slice(10, 15).map(({ attributes: { name, url } }) => (
            <ListItem
              key={name}
              label={name}
              value={url}
              className="after:w-0 after:bottom-0 hover:after:w-[25px]"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const LegalDocumentsBlock: FC<{
  title: string;
  documents: ApiLegalDocumentLegalDocument[];
}> = ({ title, documents }) => {
  return (
    <div className={'w-full mt-4'}>
      <div className="uppercase font-bold">{title}</div>
      <div className={'flex flex-col'}>
        {documents.map((doc) => (
          <ListItem
            key={doc.attributes.name}
            value={getStaticUrl(
              doc.attributes.document.data.attributes.url,
              true
            )}
            label={doc.attributes.display_name}
            className="after:w-0 after:bottom-0 hover:after:w-[25px]"
          />
        ))}
      </div>
    </div>
  );
};

const CompanyPhoneMail: FC<ICompanyPhoneMail> = ({
  company,
  phone,
  mail,
  className,
}) => (
  <div
    className={`flex gap-1 flex-col text-sm leading-tight tracking-wider+ justify-start lg:pt-14 lg:pb-32 ${className}`}
  >
    <span className="uppercase">{company}</span>
    <Link href={`tel:${phone}`}>
      <a>{phone}</a>
    </Link>
    <Link href={`mailto:${mail}`}>
      <a>{mail}</a>
    </Link>
  </div>
);

export const Footer = (props: IFooterProps) => {
  const {
    routes = [],
    footer: {
      company_entities,
      facebook_url,
      twitter_url,
      instagram_url,
      linkedin_url,
      map_image,
      company_name,
      companies_title,
      legal_documents_title,
      legal_documents,
    },
  } = props;
  const tld = process.env.NEXT_PUBLIC_SITE_LANG === 'hu' ? '.hu' : '.com';
  const intranetUrl = `https://intra.doclerholding${tld}`;

  return (
    <div className="w-full bg-[#C00000] text-[#fff] font-thin">
      <div className="flex py-8 w-full border-b border-b-[#930000] lg:py-6">
        <Container className="flex flex-1 justify-between">
          <div className="flex gap-5 items-center lg-:hidden">
            <DHIcon width={78} height={24} />
            <img
              src="/static/DoclerHolding@2x.png"
              width={235}
              height={18}
              alt="DoclerHolding"
            />
          </div>
          <div className="flex gap-x-6 justify-center lg-:w-full">
            {facebook_url && (
              <SocialLink icon={FacebookIcon} url={facebook_url} />
            )}
            {twitter_url && <SocialLink icon={TwitterIcon} url={twitter_url} />}
            {instagram_url && (
              <SocialLink icon={InstagramIcon} url={instagram_url} />
            )}
            {linkedin_url && (
              <SocialLink icon={LinkedinIcon} url={linkedin_url} />
            )}
          </div>
        </Container>
      </div>
      <Container className="lg-:px-8">
        <div className="flex flex-col leading-9 tracking-wider+ text-lg py-8 border-b border-b-[#930000] lg:py-14 lg:text-sm lg:leading-[1.9rem]">
          <div className={'flex w-full'}>
            <MenuItemsBlock title={company_name} routes={routes} />
            <CompaniesBlock title={companies_title} routes={company_entities} />
          </div>
          <LegalDocumentsBlock
            documents={legal_documents}
            title={legal_documents_title}
          />
        </div>
        <div className="flex lg-:flex-col lg-:pt-10">
          <CompanyPhoneMail
            company="docler luxembourg"
            phone="+352 26 11 18 1"
            mail="info@doclerholding.lu"
            className="lg-:mb-10 lg:mr-16"
          />
          <CompanyPhoneMail
            company="docler budapest"
            phone="+36 30 704 6700"
            mail="info@doclerholding.hu"
            className="mr-16"
          />
          <div className="relative w-full h-[98px] sm:h-[150px] my-8 lg:my-9 lg:h-auto">
            <Image
              src={getStaticUrl(map_image.attributes.url)}
              layout="fill"
              objectFit="contain"
              alt="FooterMap"
              className="object-center lg:object-right"
            />
          </div>
        </div>
      </Container>

      <div className="w-full py-8 border-t text-sm leading-tight border-t-[#930000] lg:py-4 lg-:text-center">
        <Container className="flex align-center justify-between gap-2">
          <div className="text-left">
            Docler Holding ©2022 -{' '}
            {process.env.NEXT_PUBLIC_SITE_LANG === 'hu'
              ? 'Minden jog fenntartva'
              : 'All rights reserved'}
            .
          </div>
          <Link href={intranetUrl}>
            <a className="color-white text-sm uppercase" target="_blank">
              Intranet
            </a>
          </Link>
        </Container>
      </div>
    </div>
  );
};
